import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import fortunecookie from "../../images/icons/fortunecookie.png";

function AboutPage() {
  return (
    <Box
      sx={{
        padding: {xs:'10rem 0 5rem 0', md:"10rem 0 10rem 0"},
      }}
    >
      <Box className="title" sx={{ paddingBottom: "30px" }}>
        <h1 className="buffetTitle">About Us</h1>
        <div className="underline"/>
      </Box>

      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", padding: "0 25px" }}
      >
        <Grid item xs={12} md={6} xl={4}>
          <p
            style={{
              "font-family": "Patrick Hand",
              "font-size": "5vh",
              color: "inherit",
            }}
          >
            Family owned & operated
          </p>

          <p style={{ "font-size": "2vh" }} className="buffetText">
            China Grand Buffet was founded in Boise, Idaho and operating for
            over 20 years.
            <br />
            <br />
            We proudly serve the Treasure Valley and always try our best to
            provide the most affordable pricing. We serve a vast variety of food
            with more then 200+ items!
            <br />
            <br />
            Along with our all you can eat buffet option, you can also order
            food by the pound, or you can order off our menu!
            <br />
            <br />
            We can't wait to serve you and hope you enjoy your visit!
          </p>
        </Grid>

        <Grid item md={5} xl={4} sx={{ display: "flex", alignItems: "center" }}>
          <Image
            fluid
            style={{ width: "500px" }}
            src={fortunecookie}
            alt="fortunecookie"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutPage;
