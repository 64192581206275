import { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const NavBar = () => {
  const [active, setActive] = useState("");
  // display: { xs: "none", md: "flex" },

  return (
    <>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        style={{ padding: "20px", borderBottom: "0px #bc8d4b solid" }}
      >
        {/* <Container> */}
        <Navbar.Brand
          href="/"
          style={{
            "font-size": "22px",
            fontFamily: "Noto Sans SC",

            color: "white",
            fontWeight: "800",
          }}
        >
          CHINA GRAND BUFFET
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto"></Nav>
          {/* right side nav bar */}

          <Nav
            style={{
              fontSize: "18px",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              // fontFamily: "Noto Sans SC",
            }}
          >
            <Nav.Link
              eventKey="i"
              to="/about"
              as={Link}
              onClick={() => setActive("about")}
              // style={{ color: active == "about" ? "white" : ""}}
              style={
                active == "about" ? styles.activeButton : styles.inactiveButton
              }
            >
              ABOUT
            </Nav.Link>

            <Nav.Link
              eventKey="i"
              to="/menu"
              as={Link}
              onClick={() => setActive("menu")}
              style={
                active == "menu" ? styles.activeButton : styles.inactiveButton
              }
            >
              MENU
            </Nav.Link>

            <Nav.Link
              eventKey="i"
              to="/details"
              as={Link}
              onClick={() => setActive("details")}
              style={
                active == "details"
                  ? styles.activeButton
                  : styles.inactiveButton
              }
            >
              BUFFET HOURS & PRICES
            </Nav.Link>

            <Nav.Link
              eventKey="i"
              to="/contact"
              as={Link}
              onClick={() => setActive("contact")}
              style={
                active == "contact"
                  ? styles.activeButton
                  : styles.inactiveButton
              }
            >
              CONTACT
            </Nav.Link>

            {/* <Nav.Link>
              <Button
                style={{
                  // boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.1)",
                  fontWeight: 600,
                  color: "white",
                  background: "#bc8d4b",
                  border: "1px solid white",
                }}
                size="md"
                // variant="warning"
                href="Tel: 208-321-9888"
                display="flex"
                alignItems="center"
              >
                <LocalPhoneIcon
                  style={{ fontSize: 20 }}
                  href="Tel: 208-321-9888"
                />
                CALL US!
              </Button>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  );
};
export default NavBar;

const styles = {
  activeButton: {
    color: "#bc8d4b",
  },
  inactiveButton: {
    color: "white",
    fontWeight: 400,
  },
};
