import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Grid, Input, TextField } from "@mui/material";

export default function Categories({ categories, filterItems, clickedButtonHandler, isActive }) {

  return (
    <Box
      sx={{
        marginBottom: "4rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {categories.map((category, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Button
              sx={{
                background: isActive === category ? "#c59d5f" : null,
                color: isActive === category ? "black" : null,
                marginRight: "20px",
                padding: "12px 20px",
                fontWeight: isActive === category ? "bold" : null,
                height: "40px",
                fontSize: "16px",
              }}
              key={index}
              onClick={() => {
                filterItems(category);
                clickedButtonHandler(category);
              }}
            >
              {category}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}
