import eggroll from "../../images/menu/eggrolls.jpeg";
import porkeggroll from '../../images/menu/porkeggroll.jpeg'
import chickenwing from '../../images/menu/chickenwing.jpeg'
import teriyaki from '../../images/menu/teriyaki.jpeg'
import crabrangoon from '../../images/menu/crabrangoon.jpeg'
import eggdropsoup from '../../images/menu/eggdropsoup.jpeg'
import hotsoursoup from '../../images/menu/hotsoursoup.jpeg'
import sugardonut from '../../images/menu/sugardonut.jpeg'
import misosoup from '../../images/menu/misosoup.jpeg'
import chickensoup from '../../images/menu/chickennoodlesoup.jpeg'
import wontonsoup from '../../images/menu/wontonsoup.jpeg'
import porkrice from '../../images/menu/roastporkrice.jpeg'
import chickenrice from '../../images/menu/chickenrice.jpeg'
import veglomein from '../../images/menu/veglomein.jpeg'
import beefrice from '../../images/menu/beefrice.jpeg'
import porklomein from '../../images/menu/porklomein.jpeg'
import shrimprice from '../../images/menu/shrimprice.jpeg'
import roastduck from '../../images/menu/roastduck.jpeg'
import chickenbroccoli from '../../images/menu/chickenbroccoli.jpeg'
import generalchicken from '../../images/menu/generalchicken.jpeg'
import orangechicken from '../../images/menu/orangechicken.jpeg'
import sweetpork from '../../images/menu/sweetsourpork.jpeg'
import beefbroccoli from '../../images/menu/beefbroccoli.jpeg'
import sweetchicken from '../../images/menu/sweetchicken.jpeg'
import chickenlomein from '../../images/menu/chickenlomein.jpeg'
import shrimplomein from '../../images/menu/shrimplomein.jpeg'
import beeflomein from '../../images/menu/beeflomein.jpeg'



const menu = [
  {
    id: 1,
    title: "Vegetable Egg Roll (1)",
    category: "appetizers",
    price: '1.75',
    img: eggroll,
    description: `Comes with one piece eggroll.`,
  },
  {
    id: 2,
    title: "Roast Pork Egg Roll (1)",
    category: "appetizers",
    price: '1.50',
    img: porkeggroll,
    description: `Comes with one piece roast pork egg roll.`,
  },
  {
    id: 3,
    title: "Chicken Wing (6)",
    category: "appetizers",
    price: '6.50',
    img: chickenwing,
    description: ``,
  },
  {
    id: 4,
    title: "Steamed Dumplings (10)",
    category: "appetizers",
    price: '6.50',
    img: "",
    description: ``,
  },
  {
    id: 5,
    title: "Fried Dumplings (10)",
    category: "appetizers",
    price: '6.50',
    img:'',
    description: ``,
  },
  {
    id: 6,
    title: "Teriyaki Chicken (5) ",
    category: "appetizers",
    price: '6.00',
    img: teriyaki,
    description: ``,
  },
  {
    id: 7,
    title: "Crab Rangoon (8)",
    category: "appetizers",
    price: 5.25,
    img: crabrangoon,
    description: `Comes with 8 pieces crab rangoon.`,
  },
  {
    id: 8,
    title: "Sugar Donut (10)",
    category: "appetizers",
    price: '5.50',
    img: sugardonut,
    description: ``,
  },
  {
    id: 9,
    title: "Hot & Sour Soup",
    category: "appetizers",
    price: '4.50',
    img: hotsoursoup,
    description: ``,
  },

  {
    id: 10,
    title: "Wonton Soup",
    category: "appetizers",
    price: '4.50',
    img:wontonsoup,
    description: ``,
  },

  {
    id: 11,
    title: "Egg Drop Soup",
    category: "appetizers",
    price: 4.25,
    img: eggdropsoup,
    description: ``,
  },

  {
    id: 12,
    title: "Japanese Miso Soup",
    category: "appetizers",
    price: '4.50',
    img:misosoup,
    description: ``,
  },

  {
    id: 13,
    title: "Chicken Noodle Soup",
    category: "appetizers",
    price: '4.50',
    img: chickensoup,
    description: ``,
  },

  {
    id: 14,
    title: "Roast Pork Fried Rice",
    category: "main",
    price: '8.50',
    img: porkrice,
    description: ``,
  },

  {
    id: 15,
    title: "Chicken Fried Rice",
    category: "main",
    price: '8.50',
    img: chickenrice,
    description: ``,
  },

  {
    id: 16,
    title: "Beef Fried Rice",
    category: "main",
    price: '8.50',
    img: beefrice,
    description: ``,
  },

  {
    id: 17,
    title: "Shrimp Fried Rice",
    category: "main",
    price: '9.00',
    img: shrimprice,
    description: ``,
  },

  {
    id: 18,
    title: "House Special Fried Rice",
    category: "main",
    price: '9.00',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: ``,
  },

  // MAIN

  {
    id: 19,
    title: "Vegetable Lo Mein",
    category: "main",
    price: '8.50',
    img: veglomein,
    description: `Soft Noodle`,
  },

  {
    id: 20,
    title: "Roast Pork Lo Mein",
    category: "main",
    price: '8.50',
    img:porklomein,
    description: `Soft Noodle`,
  },

  {
    id: 21,
    title: "Chicken Lo Mein",
    category: "main",
    price: '8.50',
    img: chickenlomein,
    description: `Soft Noodle`,
  },

  {
    id: 22,
    title: "Beef Lo Mein",
    category: "main",
    price: '9.00',
    img: beeflomein,
    description: `Soft Noodle`,
  },

  {
    id: 23,
    title: "Shrimp Lo Mein",
    category: "main",
    price: '9.00',
    img: shrimplomein,
    description: `Soft Noodle`,
  },

  {
    id: 24,
    title: "House Special Lo Mein",
    category: "main",
    price: '9.00',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Soft Noodle`,
  },

  {
    id: 25,
    title: "Chicken Chow Mein",
    category: "main",
    price: '8.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with Dry Noodle & White Rice`,
  },

  {
    id: 26,
    title: "Pork Chow Mein",
    category: "main",
    price: '8.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with Dry Noodle & White Rice`,
  },

  {
    id: 27,
    title: "Beef Chow Mein",
    category: "main",
    price: '8.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with Dry Noodle & White Rice`,
  },

  {
    id: 28,
    title: "Shrimp Chow Mein",
    category: "main",
    price: '8.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with Dry Noodle & White Rice`,
  },

  {
    id: 29,
    title: "Mixed Vegetables Chow Mein",
    category: "main",
    price: '8.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with Dry Noodle & White Rice`,
  },

  {
    id: 30,
    title: "Moo Goo Gai Pan",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 31,
    title: "Chicken w. Broccoli",
    category: "main",
    price: '9.75',
    img: chickenbroccoli,
    description: `Served with White Rice`,
  },

  {
    id: 32,
    title: "Pineapple Chicken",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 33,
    title: "Sweet & Sour Chicken",
    category: "main",
    price: '9.75',
    img: sweetchicken,
    description: `Served with White Rice`,
  },

  {
    id: 34,
    title: "Chicken w. Cashier Nuts",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 35,
    title: "Chicken w. Garlic Sauce",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 36,
    title: "Chicken w. Vegetable",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 37,
    title: "Black Pepper Chicken",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 38,
    title: "Peanut Butter Chicken",
    category: "main",
    price: '10.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 39,
    title: "Beef w. Garlic Sauce",
    category: "main",
    price: '10.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 40,
    title: "Beef w. Broccoli",
    category: "main",
    price: '10.50',
    img: beefbroccoli,
    description: `Served with White Rice`,
  },

  {
    id: 41,
    title: "Pepper Steak w. Onion",
    category: "main",
    price: '10.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 42,
    title: "Beef w. Mushroom",
    category: "main",
    price: '10.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 43,
    title: "Mongolian Beef",
    category: "main",
    price: '10.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 44,
    title: "Twice Cooked Pork",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 45,
    title: "Sweet & Sour Pork",
    category: "main",
    price: '9.75',
    img: sweetpork,
    description: `Served with White Rice`,
  },

  {
    id: 46,
    title: "Pork w. Garlic Sauce",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 47,
    title: "Pork w. Mushroom",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 48,
    title: "Boneless Rib & Honey",
    category: "main",
    price: '9.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 49,
    title: "Shrimp w. Garlic Sauce",
    category: "main",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 50,
    title: "Shrimp w. Mushroom",
    category: "main",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 51,
    title: "Shrimp w. Broccoli",
    category: "main",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 52,
    title: "Hot & Spicy Shrimp",
    category: "main",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 53,
    title: "Shrimp with Lobster Sauce",
    category: "main",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 54,
    title: "Black Pepper Shrimp",
    category: "main",
    price: '13.95',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Served with White Rice`,
  },

  {
    id: 55,
    title: "Four Season",
    category: "Specialties",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 56,
    title: "Kung Pao Triple Crown",
    category: "Specialties",
    price: '11.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 57,
    title: "Sesame Chicken",
    category: "Specialties",
    price: '10.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 58,
    title: "Hunan Chicken",
    category: "Specialties",
    price: '10.50',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 59,
    title: "Orange Chicken",
    category: "Specialties",
    price: '10.50',
    img: orangechicken,
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 60,
    title: "General Tsos Chicken",
    category: "Specialties",
    price: '10.50',
    img: generalchicken,
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 61,
    title: "Hunan Beef",
    category: "Specialties",
    price: '10.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 62,
    title: "Seafood Delight",
    category: "Specialties",
    price: '11.75',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 63,
    title: "Broccoli with Garlic Sauce",
    category: "Specialties",
    price: '9.25',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 64,
    title: "Roast Duck (half)",
    category: "Specialties",
    price: "15.00",
    img: roastduck,
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: "64a",
    title: "Roast Duck (whole)",
    category: "Specialties",
    price: "27.00",
    img:roastduck,
    description: `Chef Specialty - Served with White Rice`,
  },

  {
    id: 65,
    title: "Sweet & Sour Chicken",
    category: "Lunch Special",
    price: '8.00',
    img: sweetchicken,
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 66,
    title: "Sweet & Sour Pork",
    category: "Lunch Special",
    price: '8.00',
    img: sweetpork,
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 67,
    title: "Beef with Broccoli",
    category: "Lunch Special",
    price: '8.00',
    img: beefbroccoli,
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 68,
    title: "Chicken with Broccoli",
    category: "Lunch Special",
    price: '8.00',
    img: chickenbroccoli,
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 69,
    title: "Pork with Garlic Sauce",
    category: "Lunch Special",
    price: '8.00',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 70,
    title: "Chicken with Garlic Sauce",
    category: "Lunch Special",
    price: '8.00',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 71,
    title: "Mixed Vegetables",
    category: "Lunch Special",
    price: '8.00',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 72,
    title: "Sesame Chicken",
    category: "Lunch Special",
    price: '8.00',
    // img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg/220px-Eba_and_Efo_riro_%28Vegetable_Soup%29_with_Fish.jpg",
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },

  {
    id: 73,
    title: "General Tsos Chicken",
    category: "Lunch Special",
    price: '8.00',
    img:generalchicken,
    description: `Lunch Special - Available only from Monday to Friday from 10:30am to 3:00pm. Served with Fried Rice, Egg Roll & Soup. `,
  },
];
export default menu;
