import React from "react";
import Image from "react-bootstrap/Image";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import eggrolls from "../../images/icons/eggrolls.png";

const lunchPrices = [
  { name: "Adult: $12.00" },
  { name: "Children: $6.50" },
  { name: "To-Go: $6.75/lb" },
];

const dinnerPrices = [
  { name: "Adult: $15.75" },
  { name: "Children: $8.50" },
  { name: "To-Go: $8.75/lb" },
];

const hours = [
  { hour: "Monday: 10:30am - 9:30PM" },
  { hour: "Tuesday: 10:30am - 9:30PM" },
  { hour: "Wednesday: 10:30am - 9:30PM" },
  { hour: "Thursday: 10:30am - 9:30PM" },
  { hour: "Friday: 10:30am - 10:00PM" },
  { hour: "Saturday: 10:30am - 10:00PM" },
  { hour: "Sunday: 10:30am - 10:00PM" },
];

function DetailsPage() {
  return (
    <Box
      sx={{
        margin: "10rem 0",
      }}
    >
      <Box className="title" sx={{ paddingBottom: "30px" }}>
        <h1 className="buffetTitle">Buffet Details</h1>
        <div className="underline"></div>
      </Box>

      <Box display="flex" justifyContent={"center"}>
        <Box display={"flex"} width={{ xs: "90%", md: "75%" }}>
          <Grid container display={"flex"} alignItems={"flex-end"}>
            <Grid item xs={12} md={6}>
              <p className="buffetHeader" style={{ "font-size": "3vh" }}>
                Lunch Prices (until 3:30pm):
              </p>
              {lunchPrices.map((prices) => (
                <Box fontSize={18} className="buffetText">
                  {prices.name}
                </Box>
              ))}

              <p
                className="buffetHeader"
                style={{ "font-size": "3vh", paddingTop: 15 }}
              >
                Dinner Prices
              </p>
              {dinnerPrices.map((prices) => (
                <Box fontSize={18} className="buffetText">
                  {prices.name}
                </Box>
              ))}

              <p
                className="buffetHeader"
                style={{ "font-size": "3vh", paddingTop: 15 }}
              >
                Hours of Operation
              </p>
              {hours.map((hour) => (
                <Box fontSize={18} className="buffetText">
                  {hour.hour}
                </Box>
              ))}
            </Grid>

            <Grid item xs={12} md={4}>
              <Image fluid style={{ width: "310px" }} src={eggrolls} />

              <p
                className="buffetHeader"
                style={{ "font-size": "3vh", paddingTop: 15 }}
              >
                Additional Info
              </p>

              <p style={{ "font-size": "18px" }} className="buffetText">
                - Children 1-2 years free <br />
                - Children 3-10 years children price<br />
                - Children 11 and older adult price<br/>
                <br/>
                <strong>- All day dinner price</strong> on Saturdays, Sundays,
                and Holidays.
                <br />
                <strong>- All prices subject to change without notice</strong>
                <br />
                - Seniors are 10% off
                <br />
                <strong style={{ color: "orange" }}>
                  - Take out buffet is available by weight
                </strong>
              </p>
            </Grid>
          </Grid>
          {/* <Row className="justify-content-md-center">
            <Col xs={12} s={12} md={6}>
              <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Hours:
              </p>
              <p style={{ "font-size": "2vh" }} className="buffetText">
                Monday: 10:30am - 9:30PM <br />
                Tuesday: 10:30am - 9:30PM <br />
                Wednesday: 10:30am - 9:30PM <br />
                Thursday: 10:30am - 9:30PM <br />
                Friday: 10:30am - 10:00PM
                <br />
                Saturday: 10:30am - 10:00PM
                <br />
                Sunday: 10:30am - 10:00PM{" "}
              </p>

              <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Lunch Prices (until 3:30pm):
              </p>

              {lunchPrices.map((prices) => (
                <Box fontSize={18} className="buffetText">
                  {prices.name}
                </Box>
              ))}
            </Col>

            <Col md="auto" style={{ padding: "5%" }}>
              <Image fluid style={{ width: "310px" }} src={eggrolls} />
            </Col>

            <Col xs={12} md={6} lg={7} xl={6}>
              <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Dinner Prices:
              </p>
              <p className="buffetText" style={{ "font-size": "2vh" }}>
              {lunchPrices.map((prices) => (
                <Box fontSize={18} className="buffetText">
                  {prices.name}
                </Box>
              ))}
              </p>
            </Col>

            <Col xs={12} md={6} lg={4} xl={4}>
              <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Notes:
              </p>

              <p style={{ "font-size": "2vh" }} className="buffetText">
                Children under 3 eat free <br />
                10 and older is adult price <br />
                <strong>All day dinner buffet</strong> on Saturdays, Sundays,
                and Holidays.
                <br />
                <strong>All prices subject to change without notice</strong>
                <br />
                <strong style={{ color: "orange" }}>
                  Take out buffet is available by weight
                </strong>
                Seniors are 10% off
              </p>
            </Col>
          </Row> */}
        </Box>
      </Box>
    </Box>
  );
}

export default DetailsPage;
