import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";
import useWindowSize from "../../components/Hooks/index";
import background from "../../images/landing.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LandingImage from "../../images/TextExport.png";
import { makeStyles } from "@material-ui/core/styles";
import HotFood from "../../images/HotFood.jpg";
{
  /* <img src={LandingImage} height='300px'/> */
}
//
export default function Landing() {
  const [screenHeight, setScreenHeight] = useState(null);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    // console.log('height :',window.innerHeight)
  }, []);

  useEffect(() => {
    document.body.classList.add("page-overflow-hidden");
    return () => {
      document.body.classList.remove("page-overflow-hidden");
    };
  }, []);

  return (
    <Box style={style.background}>
      <Box component="img" src={HotFood} style={style.image} />
      {/* regular */}
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        // display={{ xs: "none", md: "flex" }}
        justifyContent="center"
        flexDirection="column"
        paddingTop={10}
        // Add positioning styles to place content on top of the background
        // Make sure content is above the background
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Box
          flexDirection={"column"}
          pb={5}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography variant="h4" fontWeight={600} color="white">
            ALL YOU CAN EAT
          </Typography>
          <Typography variant="h2" fontWeight={600} color="orange">
            BUFFET
          </Typography>
          <Box className="underlineLanding" sx={{ width: "75%" }} />
        </Box>

        <Box display="flex" alignItems={"center"} justifyContent={"center"}>
          <Typography color="white" typography={"h7"} textAlign={"center"}>
            10498 W. Fairview Ave. Boise, Idaho 83704
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} justifyContent={"center"}>
          <Link style={{ textDecoration: "none" }} to="/details">
            <Box
              style={style.button}
              sx={{
                mt: 3,
                background: "#bc8d4b",
                "&:hover": {
                  backgroundColor: "#000",
                },
              }}
            >
              VIEW BUFFET INFO
            </Box>
          </Link>
        </Box>
      </Grid>
    </Box>
  );
}

const style = {
  image: {
    width:'100%',
    // width: "100vw",
    minHeight: "100vh",
    opacity: ".3",
  },
  background: {
    backgroundPosition: "center",
    backgroundColor: "black",
    // width: "100vw",
    minHeight: "100vh",
    // position: "relative", // Add this to make the content position relative to the background
  },
  container: {
    margin: 0,
    height: "100vh",
    overflowY: "scroll",
    backgroundColor: "#cb2b2e",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: 75,
    /* Add a media query to adjust the height for mobile */
    "@media (max-width: 768px)": {
      height: "auto",
      // paddingBottom: '10%',
    },
  },

  button: {
    border: "2px solid white",
    fontSize: "18px",
    color: "white",
    fontWeight: 600,
    height: "50px",
    width: "220px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
  },
};
