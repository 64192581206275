import React from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SocialIcon } from "react-social-icons";
import Image from "react-bootstrap/Image";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import buns from "../../images/icons/buns.png";

const BackgroundStyled = styled.div`
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10rem 0;
`;

function ContactPage() {
  return (
    <BackgroundStyled>
      <section>
        <Box sx={{ paddingBottom: "30px" }} className="title">
          <h1 className="buffetTitle">Contact Us</h1>
          <div className="underline"></div>
        </Box>

        <Container >
          <Row className="justify-content-md-center">
            <Col md="auto" style={{ paddingX: "5%", display:'flex',  justifyContent:'center', paddingTop:10}}>
              {/* <Image fluid style={{ width: "500px" }} src={buns} /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.351350241946!2d-116.31543072353236!3d43.62004567110357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54ae55d6a6400001%3A0xc267acfee70e744a!2s10498%20Fairview%20Ave%2C%20Boise%2C%20ID%2083704!5e0!3m2!1sen!2sus!4v1692251550288!5m2!1sen!2sus"
                width="400"
                height="350"
                // style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>

            <Col xs={12} s={12} md={6} lg={5} style={{ paddingTop:5, display:'flex', alignItems:'center', flexDirection:'column' }}>
            <Box>
            <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Come visit us:
              </p>
              <p className="buffetText" style={{ "font-size": "2vh" }}>
                10498 w. Fairview Ave.
                <br />
                Boise, Idaho 83704
                <br />
                <br />
                We are located on the corner of Fairview & File Mile!
              </p>

              <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Contact us:
              </p>
              <p className="buffetText" style={{ "font-size": "2vh" }}>
                208-321-9888
              </p>

              <p className="buffetHeader" style={{ "font-size": "4vh" }}>
                Connect with us:
              </p>

              <SocialIcon url="https://www.facebook.com/ChinaGrandBoise/" />
              <span style={{ color: "white" }}>___</span>

              <SocialIcon url="https://www.yelp.com/biz/china-grand-buffet-boise" />
            </Box>
      
            </Col>
          </Row>
        </Container>
      </section>
    </BackgroundStyled>
  );
}

export default ContactPage;
