import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Home/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./index.css";
import MenuPage from "./pages/MenuPage";
import NavBar from "./components/NavBar";
import AboutPage from "../src/pages/AboutPage";
import DetailsPage from "./pages/DetailsPage";
import ContactPage from "./pages/ContactPage";
import Footer from "../src/components/Footer";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#29292e",
      },
      secondary: {
        main: "#FFAF60",
      },
    },
  });

  return (
    // <div>
      <ThemeProvider theme={theme}>
        <NavBar />

        <Routes>
          <Route path="/" element={<Landing />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/menu" element={<MenuPage />}></Route>
          <Route path="/details" element={<DetailsPage />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
        </Routes>

        {/* <Footer/> */}
      </ThemeProvider>
    // </div>
  );
}

export default App;
