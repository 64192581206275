import React, { useState, useEffect } from "react";
import items from "./data";
import Menu from "../../components/Menu";
import styled from "styled-components";
import { Box, Button, Typography, Grid, Input, TextField } from "@mui/material";
import MenuTest from "../../components/Menu/MenuTest";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import Categories from "../../components/Menu/Categories";

const BackgroundStyled = styled.div`
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10rem 0;
`;

function MenuPage() {
  const allCategories = ["all", ...new Set(items.map((item) => item.category))];
  const [menuItems, setMenuItems] = useState(items);
  const [categories, setCategories] = useState(allCategories);
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState("all");
  const clickedButtonHandler = (categories) => {
    setIsActive(categories);
  };

  const filterItems = (category) => {
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
    setSearch("");
  };

  useEffect(() => {
    if (isActive === "all") {
      setMenuItems(items);
      return;
    }
  }, [isActive]);

  return (
    <BackgroundStyled>
      <ScrollToTopButton />

      <Grid
        container
        className="title"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item md={4}></Grid>
        <Grid item xs={12} md={4} sx={{ pb: 5 }}>
          <h1 className="buffetTitle">Menu</h1>
          <div className="underline"></div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <TextField
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            label="Search Menu"
            style={{ width: 275 }}
            value={search}
          />
        </Grid>
      </Grid>

      <Categories
        categories={categories}
        filterItems={filterItems}
        clickedButtonHandler={clickedButtonHandler}
        isActive={isActive}
      />
      <MenuTest
        search={search}
        menuItems={menuItems}
        setSearch={setSearch}
        setIsActive={setIsActive}
      />
      {/* <Menu menuItems={menuItems} setMenuItems={setMenuItems} search={search} /> */}
    </BackgroundStyled>
  );
}

export default MenuPage;
