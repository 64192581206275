import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardMedia,
  Divider,
} from "@mui/material";
import stockimage from "../../images/stockimage2.jpeg";

function MenuTest({ menuItems, search, setSearch, setIsActive }) {
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  //   console.log('menu items :', menuItems)
  const isWordPresent = menuItems.some((item) =>
    item.title.toLowerCase().includes(search.toLowerCase())
  );
  console.log("isWordPresent :", isWordPresent);

  return (
    <Grid
      container
      spacing={4}
      paddingX={{ xs: 5, lg: 20 }}
      display={"flex"}
      justifyContent={"flex-start"}
    >
      {isWordPresent ? (
        menuItems
          ?.filter((val) => {
            if (val.title.toLowerCase().includes(search.toLowerCase())) {
              return val;
            }
          })
          .map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box display="flex" flexDirection={"row"}>
                <Box>
                  {isXsScreen ? null : (
                    <Box
                      component="img"
                      alt={item.title}
                      src={item.img ? item.img : stockimage}
                      style={styles.photo}
                    />
                  )}
                </Box>

                <Box paddingLeft={2} width="100%">
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    // backgroundColor="red"
                  >
                    <Typography variant="h6" fontWeight={500}>
                      {item.title}
                    </Typography>

                    <Typography variant="body1" className="price">
                      ${item.price}
                    </Typography>
                  </Box>

                  <Divider style={{ marginBottom: 5 }} />
                  <Typography variant="subtitle2">
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))
      ) : (
        <Box
          width="100%"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          The item you searched does not exist - please search for something
          else.
          <Button
            onClick={() => {
              setSearch("");
              // clickedButtonHandler("all");
              setIsActive("all");
            }}
            style={{ backgroundColor: "black", color: "white", marginTop: 30 }}
          >
            Reset Search
          </Button>
        </Box>
      )}
    </Grid>
  );
}

export default MenuTest;

const styles = {
  photo: {
    objectFit: "cover",
    height: "125px",
    width: "175px",
    // width: "100%",
    border: "0.25rem solid var(--clr-gold)",
    borderRadius: "var(--radius)",
    // display: "block",
  },
};
